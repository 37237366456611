import AppRoutes from "./routes/routes";

function App() {

  return (
      <AppRoutes />
  );
}

export default App;
