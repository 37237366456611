import Banner from "../../components/Banner";
import ButtonWhats from "../../components/Button_Whats";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import GoToTop from "../../components/GoToTop/GoToTop";
import Header from "../../components/Header";
import styles from "./PrivacyPolicy.module.css";

function PrivacyPolicy(){
    return(
        <section className={styles.container}>
            <Header />
            <Banner banner={"politicas_de_privacidade"} page={"Políticas de Privacidade"} logo_banner={true}/>
            <Container>
                <h1> </h1>
            </Container>
            <p className={styles.text}>
                Na RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS, privacidade e
                segurança são prioridades e nos comprometemos com a transparência do tratamento de
                dados pessoais dos nossos usuários/clientes. Por isso, a presente Política de Privacidade
                estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras
                pessoas que acessam ou usam o nosso site.<br/><br/>
                Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações
                pessoais nas formas descritas nesta Política, sob as normas da Constituição Federal de
                1988 (art. 5º, LXXIX; e o art. 22º, XXX – incluídos pela EC 115/2022), das normas de
                Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da
                Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.<br/><br/>
                Dessa forma, o RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS,
                doravante denominada simplesmente como RFN ADVOGADOS ASSOCIADOS
                inscrita no CNPJ/MF sob o nº (51.980.078/0001-50), no papel de Controladora de Dados,
                obriga-se ao disposto na presente Política de Privacidade.<br/><br/>
            </p>
            <h1 className={styles.titulo}>1. COLETA E TRATAMENTO DE DADOS</h1>
            <p className={styles.text}>
                Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação
                de serviços e aprimorar a experiência de uso.<br/><br/>
                Importante destacar que não serão enviados e-mails solicitando que o USUÁRIO
                encaminhe seus dados cadastrais.<br/><br/>
                Internamente, os dados de que coletamos são acessados somente por profissionais
                devidamente autorizados, respeitando os princípios de proporcionalidade, necessidade e
                relevância para os objetivos do site mantido por RENARD, FRIEDRICH & NEU
                ADVOGADOS ASSOCIADOS, além do compromisso de confidencialidade e
                preservação da privacidade nos termos desta Política de Privacidade.
            </p>
            <h1 className={styles.titulo}>1.1. DADOS PESSOAIS FORNECIDOS PELO TITULAR</h1>
            <p className={styles.text}>
                Ao acessar o site você poderá fornecer alguns dados pessoais como: nome completo,
                telefone e e-mail.<br/><br/>
                A coleta desses tem por finalidade a aproximação e a facilitação do contato entre cliente
                e o escritório RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS, bem
                como atender as solicitações dos titulares de dados pessoais. Tem como base legal o
                consentimento do titular (art. 7º, I, LGPD).
            </p>
            <h1 className={styles.titulo}>1.2 DADOS PESSOAIS COLETADOS AUTOMATICAMENTE AO ACESSAR O SITE</h1>
            <p className={styles.text}>
                Ao acessar o site do RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS
                alguns dados são coletados automaticamente, conforme descrito na nossa política de
                Cookies, descrita no tópico “8” da presente política de privacidade.
            </p>
            <h1 className={styles.titulo}>2. CONSENTIMENTO</h1>
            <p className={styles.text}>
                É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é
                a manifestação livre, informada e inequívoca pela qual você autoriza a RENARD,
                FRIEDRICH & NEU ADVOGADOS ASSOCIADOS a tratar seus dados. Assim, em
                consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados,
                tratados e armazenados mediante prévio e expresso consentimento.<br/><br/>
                O seu consentimento será obtido de forma específica para cada finalidade acima descrita,
                evidenciando o compromisso de transparência e boa-fé da RENARD, FRIEDRICH &
                NEU ADVOGADOS ASSOCIADOS para com seus usuários/clientes, seguindo as
                regulações legislativas pertinentes.<br/><br/>
                Ao utilizar os serviços da RENARD, FRIEDRICH & NEU ADVOGADOS
                ASSOCIADOS e fornecer seus dados pessoais, você está ciente e consentindo com as
                disposições desta Política de Privacidade, além de conhecer seus direitos e como exercêlos.
                A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.<br/><br/>
                É importante destacar que a revogação do consentimento para o tratamento dos dados
                pode implicar a impossibilidade da performance adequada de alguma funcionalidade do
                site que dependa da operação. Tais consequências serão informadas previamente.
            </p>
            <h1 className={styles.titulo}>3. DIREITOS DO TITULAR</h1>
            <p className={styles.text}>
                A RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS assegura a seus
                usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção
                de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:<br/><br/>
                a. Confirmar a existência de tratamento de dados, de maneira simplificada ou
                em formato claro e completo;<br/>
                b. Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma
                impressa ou por meio eletrônico, seguro e idôneo;<br/>
                c. Corrigir seus dados, ao solicitar a edição, correção ou atualização destes;<br/>
                d. Limitar seus dados quando desnecessários, excessivos ou tratados em
                desconformidade com a legislação através da anonimização, bloqueio ou
                eliminação;<br/>
                e. Solicitar a portabilidade de seus dados, através de um relatório de dados
                cadastrais que a RENARD, FRIEDRICH & NEU ADVOGADOS
                ASSOCIADOS trata a seu respeito;<br/>
                f. Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos
                previstos em lei;<br/>
                g. Revogar seu consentimento, desautorizando o tratamento de seus dados;<br/>
                h. Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre
                as consequências da negativa.
            </p>
            <h1 className={styles.titulo}>3.1 COMO VOCÊ PODE EXERCER SEUS DIREITOS DE TITULAR?</h1>
            <p className={styles.text}>
                Para exercer seus direitos de titular, você deve entrar em contato com a RENARD,
                FRIEDRICH & NEU ADVOGADOS ASSOCIADOS através dos seguintes meios
                disponíveis:<br/><br/>
                a) Telefone: (55) 3028-2862<br/>
                b) E-mail: rfnadvocacia@gmail.com<br/><br/>
                Ou através do contato direto com nosso DPO, Encarregado de Proteção de dados, Wilian
                Mauri Friedrich Neu, através dos seguintes canais:<br/>
                a) Telefone: (55) 99984-8210<br/>
                b) E-mail: wilianfriedrichneu@gmail.com<br/><br/>
                De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da
                solicitação, é possível que solicitemos documentos ou demais comprovações que possam
                comprovar sua identidade. Nessa hipótese, você será informado previamente.
            </p>
            <h1 className={styles.titulo}>4. COMO E POR QUANTO TEMPO SEUS DADOS SERÃO ARMAZENADOS?</h1>
            <p className={styles.text}>
                Seus dados pessoais coletados pela RENARD, FRIEDRICH & NEU ADVOGADOS
                ASSOCIADOS serão utilizados e armazenados durante o tempo necessário para a
                prestação do serviço ou para que as finalidades elencadas na presente Política de
                Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos
                controladores.<br/><br/>
                De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a
                RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS perdurar. Findado o
                período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de
                dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 Lei
                Geral de Proteção de Dados, a saber:<br/><br/>
                I. cumprimento de obrigação legal ou regulatória pelo controlador;<br/>
                II. estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos
                dados pessoais;<br/>
                III. transferência a terceiro, desde que respeitados os requisitos de tratamento de
                dados dispostos nesta Lei; ou<br/>
                IV. uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que
                anonimizados os dados.<br/><br/>
                As informações pessoais sobre você que sejam imprescindíveis para o cumprimento de
                determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa
                em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos
                demais dados.<br/><br/>
                O armazenamento de dados coletados pela RENARD, FRIEDRICH & NEU
                ADVOGADOS ASSOCIADOS reflete o nosso compromisso com a segurança e
                privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas
                a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Além disso,
                também contamos com medidas de segurança apropriadas aos riscos e com controle de
                acesso às informações armazenadas.<br/><br/>
            </p>
            <h1 className={styles.titulo}>5. O QUE FAZEMOS PARA MANTER SEUS DADOS SEGUROS?</h1>
            <p className={styles.text}>
                Para mantermos suas informações pessoais seguras, usamos ferramentas físicas,
                eletrônicas, e gerenciais orientadas para a proteção da sua privacidade, além de Software
                de gestão da Privacidade de Dados.<br/><br/>
                Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais
                coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações
                gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
                Entre as medidas que adotamos, destacamos as seguintes:<br/><br/>
                I. Apenas pessoas autorizadas têm acesso a seus dados pessoais;<br/>
                II. O acesso a seus dados pessoais é feito somente após o compromisso de
                confidencialidade;<br/>
                III. Seus dados pessoais são armazenados em ambiente seguro e idôneo.<br/><br/>
                A RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS se compromete a
                adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário
                destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível
                que, apesar de todos os nossos protocolos de segurança, problemas de culpa
                exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também
                em decorrência da negligência ou imprudência do próprio usuário/cliente.<br/><br/>
                Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou
                qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade
                Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da
                Lei Geral de Proteção de Dados.
            </p>
            <h1 className={styles.titulo}>6. COM QUEM SEUS DADOS PODEM SER COMPARTILHADOS?</h1>
            <p className={styles.text}>
                Tendo em vista a preservação de sua privacidade, a RENARD, FRIEDRICH & NEU
                ADVOGADOS ASSOCIADOS não compartilhará seus dados pessoais com nenhum
                terceiro não autorizado.<br/><br/>
                Seus dados poderão ser compartilhados com nossos parceiros comerciais, desde que
                autorizados pelo Titular de Dados.<br/><br/>
                Estes recebem seus dados apenas na medida do necessário para a prestação dos serviços
                contratados e nossos contratos são orientados pelas normas de proteção de dados do
                ordenamento jurídico brasileiro.
                Todavia, nossos parceiros têm suas próprias Políticas de Privacidade, que podem
                divergir desta.<br/><br/>
                Além disso, também existem outras hipóteses em que seus dados poderão ser
                compartilhados, que são:<br/><br/>
                I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades
                judiciais, administrativas ou governamentais competentes.<br/>
                II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma
                automática<br/>
                III – Proteção dos direitos da RENARD, FRIEDRICH & NEU ADVOGADOS
                ASSOCIADOS em qualquer tipo de conflito, inclusive os de teor judicial.
            </p>
            <h1 className={styles.titulo}>7. TRANSFERÊNCIA INTERNACIONAL DE DADOS</h1>
            <p className={styles.text}>
                Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou
                possuir instalações localizadas em países estrangeiros. Nessas condições, de toda forma,
                seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais
                legislações brasileiras de proteção de dados. Nesse sentido, a RENARD, FRIEDRICH
                & NEU ADVOGADOS ASSOCIADOS se compromete a sempre adotar eficientes
                padrões de segurança cibernética e de proteção de dados, nos melhores esforços de
                garantir e cumprir as exigências legislativas.<br/><br/>
                Ao concordar com essa Política de Privacidade, você concorda com esse
                compartilhamento, que se dará conforme as finalidades descritas no presente instrumento.
            </p>
            <h1 className={styles.titulo}>8. COOKIES OU DADOS DE NAVEGAÇÃO</h1>
            <p className={styles.text}>
                A RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS faz uso de
                Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que
                nele se armazenam, que contém informações relacionadas à navegação do site. Em suma,
                os Cookies são utilizados para aprimorar a experiência de uso.<br/>
                Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar
                a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu
                dispositivo.<br/><br/>
                A RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS utiliza os
                seguintes Cookies:<br/>
                a) Cookies essenciais: são os necessários para o funcionamento da página. Eles
                viabilizam a navegação do usuário pelo site.<br/>
                b) Cookies analíticos: servem para coletar informações sobre a navegação dos
                usuários pelo site, de modo a corrigir falhas e melhorar a experiência na página.
                As informações obtidas não permitem a identificação do usuário, seja de forma
                direta ou indireta.<br/>
                c) Cookies funcionais: têm por finalidade o registro das informações previamente
                fornecidas, de maneira a aprimorar a personalização da navegação pelo usuário.<br/>
                d) Cookies de marketing: são vinculados ao direcionamento de conteúdos, anúncios,
                pesquisas, da própria RENARD, FRIEDRICH & NEU ADVOGADOS
                ASSOCIADOS e de entidades parceiras, com base na interação travada junto ao
                site.<br/>
                Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou
                recusar os Cookies. Todavia, a revogação do consentimento de determinados Cookies
                pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações
                do navegador, na área de gestão de Cookies.
            </p>
            <h1 className={styles.titulo}>9. ALTERAÇÃO DESTA POLÍTICA DE PRIVACIDADE</h1>
            <p className={styles.text}>
                A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em:
                04/12/2023.<br/><br/>
                Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo,
                principalmente em função da adequação a eventuais alterações feitas em nosso site ou em
                âmbito legislativo. Recomendamos que você a revise com frequência.
                Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre
                lhe notificaremos acerca das mudanças ocorridas.
                Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você
                as consente.
            </p>
            <h1 className={styles.titulo}>10. RESPONSABILIDADE</h1>
            <p className={styles.text}>
                A RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS prevê a
                responsabilidade dos agentes que atuam nos processos de tratamento de dados, em
                conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
                Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas
                disposições e zelando por seu cumprimento.<br/><br/>
                Além disso, também assumimos o compromisso de buscar condições técnicas e
                organizativas seguramente aptas a proteger todo o processo de tratamento de dados.
                Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em
                relação ao tratamento de dados realizado pela RENARD, FRIEDRICH & NEU
                ADVOGADOS ASSOCIADOS, comprometemo-nos a segui-las.
            </p>
            <h1 className={styles.titulo}>10.1 ISENÇÃO DE RESPONSABILIDADE</h1>
            <p className={styles.text}>
                Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a
                fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse
                sentido, a RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS não se
                responsabiliza por:<br/><br/>
                I. Quaisquer consequências decorrentes da negligência, imprudência ou imperícia
                dos usuários em relação a seus dados individuais. Garantimos e nos
                responsabilizamos apenas pela segurança dos processos de tratamento de dados e
                do cumprimento das finalidades descritas no presente instrumento. Destacamos
                que a responsabilidade em relação à confidencialidade dos dados de acesso é do
                usuário.<br/>
                II. Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada
                conduta culposa ou deliberada da RENARD, FRIEDRICH & NEU
                ADVOGADOS ASSOCIADOS. Destacamos que em caso de incidentes de
                segurança que possam gerar risco ou dano relevante para você ou qualquer um de
                nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de
                Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.<br/>
                III. Inveracidade das informações inseridas pelo usuário/cliente nos registros
                necessários para a utilização dos serviços da RENARD, FRIEDRICH & NEU
                ADVOGADOS ASSOCIADOS. Quaisquer consequências decorrentes de
                informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do
                usuário/cliente.
            </p>
            <h1 className={styles.titulo}>11. ENCARREGADO DE PROTEÇÃO DE DADOS</h1>
            <p className={styles.text}>
                A RENARD, FRIEDRICH & NEU ADVOGADOS ASSOCIADOS disponibiliza os
                seguintes meios para que você possa entrar em contato conosco para exercer seus direitos
                de titular:<br/><br/>
                c) Telefone: (55) 3028-2862<br/>
                d) E-mail: rfnadvocacia@gmail.com<br/><br/>
                Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que
                tratamos, você pode entrar em contato com o nosso Encarregado de Proteção de Dados
                Pessoais, Wilian Mauri Friedrich Neu, através dos seguintes canais:<br/><br/>
                c) Telefone: (55) 99984-8210<br/>
                d) E-mail: wilianfriedrichneu@gmail.com<br/><br/>
                12. GLOSSÁRIO<br/><br/>
                a) DADO PESSOAL: informação relacionada a pessoa natural identificada ou
                identificável (art.5º, inc. I, LGPD);<br/>
                b) DADO PESSOAL SENSÍVEL: dado pessoal sobre origem racial ou étnica,
                convicção religiosa, opinião política, filiação a sindicato ou a organização de
                caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual,
                dado genético ou biométrico, quando vinculado a uma pessoa natural (art.5º, inc.
                II, LGPD);<br/>
                c) DADO ANONIMIZADO: dado relativo a titular que não possa ser identificado,
                considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de
                seu tratamento (art.5º, inc. III, LGPD);<br/>
                d) BANCO DE DADO: conjunto estruturado de dados pessoais, estabelecido em
                um ou em vários locais, em suporte eletrônico ou físico (art.5º, inc. IV, LGPD);<br/>
                e) TITULAR: pessoa natural a quem se referem os dados pessoais que são objeto
                de tratamento (art.5º, inc. V, LGPD);<br/>
                f) CONTROLADOR: pessoa natural ou jurídica, de direito público ou privado, a
                quem competem as decisões referentes ao tratamento de dados pessoais (art.5º,
                inc. VI, LGPD);<br/>
                g) OPERADOR: pessoa natural ou jurídica, de direito público ou privado, que
                realiza o tratamento de dados pessoais em nome do controlador (art.5º, inc. VII,
                LGPD);<br/>
                h) ENCARREGADO: pessoa indicada pelo controlador e operador para atuar como
                canal de comunicação entre o controlador, os titulares dos dados e a Autoridade
                Nacional de Proteção de Dados (ANPD) (art.5º, VIII, LGPD);<br/>
                i) AGENTE DE TRATAMENTO: o controlador e o operador (art.5º, IX, LGPD);<br/>
                j) TRATAMENTO: toda operação realizada com dados pessoais, como as que se
                referem a coleta, produção, recepção, classificação, utilização, acesso,
                reprodução, transmissão, distribuição, processamento, arquivamento,
                armazenamento, eliminação, avaliação ou controle da informação, modificação,
                comunicação, transferência, difusão ou extração (art.5º, X, LGPD);<br/>
                k) ANONIMIZAÇÃO: utilização de meios técnicos razoáveis e disponíveis no
                momento do tratamento, por meio dos quais um dado perde a possibilidade de
                associação, direta ou indireta, a um indivíduo (art.5º, XI, LGPD);<br/>
                l) CONSENTIMENTO: manifestação livre, informada e inequívoca pela qual o
                titular concorda com o tratamento de seus dados pessoais para uma finalidade
                determinada (art.5º, XII, LGPD);<br/>
                m) BLOQUEIO: suspensão temporária de qualquer operação de tratamento,
                mediante guarda do dado pessoal ou do banco de dados (art.5º, XIII, LGPD);<br/>
                n) ELIMINAÇÃO: exclusão de dado ou de conjunto de dados armazenados em
                banco de dados, independentemente do procedimento empregado (art.5º, XIII,
                LGPD);<br/>
                o) TRANSFERÊNCIA INTERNACIONAL DE DADOS: transferência de dados
                pessoais para país estrangeiro ou organismo internacional do qual o país seja
                membro (art.5º, XV, LGPD);<br/>
                p) USO COMPARTILHADO DE DADOS: comunicação, difusão, transferência
                internacional, interconexão de dados pessoais ou tratamento compartilhado de
                bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas
                competências legais, ou entre esses e entes privados, reciprocamente, com
                autorização específica, para uma ou mais modalidades de tratamento permitidas
                por esses entes públicos, ou entre entes privados (art.5º, XVI, LGPD);<br/>
                q) RELATÓRIO DE IMPACTO À PROTEÇÃO DE DADOS PESSOAIS:
                documentação do controlador que contém a descrição dos processos de tratamento
                de dados pessoais que podem gerar riscos às liberdades civis e aos direitos
                fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de
                risco (art.5º, XVII, LGPD);<br/>
                r) AUTORIDADE NACIONAL: órgão da administração pública responsável por
                zelar, implementar e fiscalizar o cumprimento desta Lei em todo o território
                nacional (art.5º, XIX, LGPD);<br/>
                s) VIOLAÇÃO DE DADOS PESSOAIS: uma violação da segurança que
                provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a
                divulgação ou o acesso, não autorizados, a dados pessoais transmitidos,
                conservados ou sujeitos a qualquer outro tipo de tratamento;
            </p>
            <ButtonWhats />
            <Footer />
            <GoToTop />
        </section>
    );
}

export default PrivacyPolicy;